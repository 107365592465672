'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useFormik } from 'formik';
import styles from './LoginPage.module.scss';
import Input from '../../../UIKit/Input/Input';
import Button from '../../../UIKit/Button/Button';
import CheckboxItem from '../../../UIKit/CheckboxItem/CheckboxItem';
import { useSchema } from './useSchema';

const LoginPage = () => {
  const t = useTranslations('loginPage');
  const validationSchema = useSchema();
  const router = useRouter();

  const [loginError, setLoginError] = useState<string>('');

  const {
    values, errors, handleChange, handleSubmit, handleBlur, isValid,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema,
    onSubmit: ({ email, password, rememberMe }) => {
      signIn('credentials', {
        email,
        password,
        redirect: false,
      }).then(result => {
        if (result?.ok) {
          rememberMe
            ? localStorage.setItem('rememberMe', 'true')
            : sessionStorage.setItem('rememberMe', 'true');
          router.push('/');
        } else {
          setLoginError(result?.error ?? '');
        }
      });
    },
  });

  return (
    <>
      <div className={styles.info}>
        <h1 className={styles.title}>{t('Log in to SDS')}</h1>
        <p className={styles.subtitle}>
          {t('Manage compliance and keep your chemical inventory up-to-date')}
        </p>
      </div>
      <form
        className={styles.form}
        onSubmit={handleSubmit}
      >
        <div className={styles.form__fields}>
          <Input
            id='loginEmailInput'
            label={t('Email')}
            name='email'
            type='email'
            value={values.email}
            setValue={handleChange}
            onBlur={handleBlur}
            errorMessage={errors.email}
          />
          <Input
            id='loginPasswordInput'
            label={t('Password')}
            name='password'
            type='password'
            value={values.password}
            setValue={handleChange}
            onBlur={handleBlur}
            errorMessage={errors.password}
          />
        </div>
        <div className={styles.form__actions}>
          <CheckboxItem
            label={t('Remember me')}
            name='rememberMe'
            labelClassName={styles.buttonFont}
            value={values.rememberMe}
            onChange={handleChange}
          />
          <Link
            className={styles.linkTextSmall}
            href='/forgot-password'
          >
            {t('Forgot your password?')}
          </Link>
        </div>
        <Button
          className={styles.form__button}
          type='submit'
          disabled={!isValid}
        >
          {t('Log In')}
        </Button>
        {loginError && <p className={styles.error}>{loginError}</p>}
      </form>
    </>
  );
};

export default LoginPage;
