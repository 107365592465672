import * as yup from 'yup';
import { useTranslations } from 'next-intl';

export const useSchema = () => {
  const t = useTranslations('validation');

  return yup.object().shape({
    email: yup.string().email(t('Invalid email address')).required(t('Email is required')),
    password: yup.string().required(t('Password is required')),
  });
};
